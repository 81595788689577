import axiosInstance from './axiosInstance';

const API_URL = '';

const ticketService = {

    createTicket: async (ticketData) => {
      try {
          const response = await axiosInstance.post(`${API_URL}/tickets`, ticketData);
          return { ...response.data, valid: true };
        } catch (error) {
          return {
            valid: false,
            error: error.response?.data?.error || 'An unknown error occurred',
          };
        }
    },

    getAllTickets: async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/tickets`);
            return { ...response.data, valid: true };
          } catch (error) {
            return {
              valid: false,
              error: error.response?.data?.error || 'An unknown error occurred',
            };
          }
    },

    getTicketById: async (ticketId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/tickets/${ticketId}`);
            return { ...response.data, valid: true };
          } catch (error) {
            return {
              valid: false,
              error: error.response?.data?.error || 'An unknown error occurred',
            };
          }
    },

    getTicketByApplicationId: async (guid) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/tickets/application/${guid}`);
            return { ...response.data, valid: true };
          } catch (error) {
            return {
              valid: false,
              error: error.response?.data?.error || 'An unknown error occurred',
            };
          }
    },

    updateStatus: async (ticketId, action, replyTo) => {
        try {
          const body = { ticketId, action, replyTo };
          const response = await axiosInstance.post(`${API_URL}/tickets/update`, body);
          return { ...response.data, valid: true };
        } catch (error) {   
            const response = await axiosInstance.post(`${API_URL}/tickets/update`, replyTo);
            return { ...response.data, valid: true };
          } 
    },

    replyToThread: async (ticketId, replyTo, content) => {
        try {
          const body = { ticketId, replyTo, content };
          const response = await axiosInstance.post(`${API_URL}/tickets/reply`, body);
          return { ...response.data, valid: true };
        } catch (error) {
          return {
            valid: false,
            error: error.response?.data?.error || 'An unknown error occurred',
          };
        }
    }

}
export default ticketService;