import React from "react";
import "./Home.css"; // Updated styling
import { useAuth } from "../contexts/AuthProvider";

const Home = () => {
  const { auth } = useAuth();
  const username = auth.userDetails.first_name + " " + auth.userDetails.last_name;

  return (
    <div className="home container mt-5">
      {/* Welcome Message */}
      <div className="text-center">
        <h1 className="welcome-message">Welcome, {username}!</h1>
        <p className="lead text-muted">Manage your applications efficiently and stay organized.</p>
      </div>

      {/* Applications Section */}
      <section className="mt-5">
        <h2 className="section-title text-center mb-4">Applications Overview</h2>
        <div className="row justify-content-center">
          {/* Card 1 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100 shadow border-0">
              <div className="card-body d-flex flex-column text-center">
                <div className="icon-container mb-3 bg-primary text-white">
                  <i className="bi bi-inbox-fill"></i>
                </div>
                <h5 className="card-title">Unclaimed Applications</h5>
                <p className="card-text text-muted">View applications that haven't been claimed yet.</p>
                <a href="/applications/unclaimed" className="btn btn-primary mt-auto">
                  Go to Unclaimed
                </a>
              </div>
            </div>
          </div>
          {/* Card 2 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100 shadow border-0">
              <div className="card-body d-flex flex-column text-center">
                <div className="icon-container mb-3 bg-success text-white">
                  <i className="bi bi-list-check"></i>
                </div>
                <h5 className="card-title">All Applications</h5>
                <p className="card-text text-muted">Access a complete list of all applications.</p>
                <a href="/applications/all" className="btn btn-success mt-auto">
                  View All
                </a>
              </div>
            </div>
          </div>
          {/* Card 3 */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100 shadow border-0">
              <div className="card-body d-flex flex-column text-center">
                <div className="icon-container mb-3 bg-warning text-white">
                  <i className="bi bi-person-lines-fill"></i>
                </div>
                <h5 className="card-title">My Applications</h5>
                <p className="card-text text-muted">View applications assigned to you.</p>
                <a href="/applications/mine" className="btn btn-warning mt-auto text-white">
                  Go to Mine
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
