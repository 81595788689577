import React, { useState } from "react";
import "./Navbar.css";
import logo from "../assets/logo.png";
import { useAuth } from "../contexts/AuthProvider";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = ({ isAdmin }) => {

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { logout, auth } = useAuth(); 
  const role = auth.role;
  const location = useLocation();

  // Handle Logout
  const handleLogout = () => {
    logout(); // Call the logout function from the AuthContext
    window.location.href = "/"; // Redirect to home or login page after logout
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-black fixed-top">
      <div className="container">
        
        {/* Logo */}
        <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="Logo" className="navbar-logo" />
          </NavLink>

        {/* Navbar Content */}
        <div className="collapse navbar-collapse order-2 order-md-1" id="navbarNavDropdown">
              <ul className="navbar-nav me-auto">
              <li className="nav-item">
              <NavLink className="nav-link" to="/" end>
                  Home
                </NavLink>
                  
                </li>
                {/* Applications Dropdown */}
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#applications" id="applicationsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Applications
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="applicationsDropdown">
                    <li><NavLink className="dropdown-item" to="/applications/unclaimed">
                      Unclaimed Applications
                    </NavLink></li>
                    {(role === "Admin" || role === "StellarUser") && (
                      <li>
                        <NavLink className="dropdown-item" to="/applications/incomplete">
                        Incomplete Applications
                      </NavLink>
                      </li>
                    )}
                    <li><NavLink className="dropdown-item" to="/applications/all">
                      All Applications
                    </NavLink></li>
                    <li><NavLink className="dropdown-item" to="/applications/mine">
                      My Applications
                    </NavLink></li>
                  </ul>
                </li>

                {/* Tickets Dropdown */}
                <li className="nav-item">
                  {(role === "Admin" || role === 'Client') && (
                     <NavLink className="nav-link" to="/tickets">
                     Tickets
                   </NavLink>
                  )}
                 
                  
                </li>

                {/* Admin (Conditional) */}
                {isAdmin && (
                  <li className="nav-item">
                    <NavLink
                    className="nav-link"
                    to="/admin"
                    // isActive={() => location.pathname === "/admin" && isAdmin} // Optional custom logic
                  >
                    Admin
                  </NavLink>
                  </li>
                )}

                {/* Dashboard */}
                <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard">
                  FAQ
                </NavLink>
                </li>
                
                
              </ul>
            </div>

            <ul className="navbar-nav flex-row ms-auto order-1 order-md-2">

              {/* Mobile Toggle */}
              <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

                {/* Notifications */}
                {/* <li className="nav-item me-3">
                  <a className="nav-link position-relative" href="/notifications">
                    <i className="bi bi-bell-fill icon"></i>
                    <span className="position-absolute top-30 start-100 translate-middle badge rounded-pill bg-danger">
                      9
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </a>
                </li> */}

                {/* Profile */}
                <li className="nav-item dropdown profile-li">
                  <button className="nav-link dropdown-toggle" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                    <i className="bi bi-person-circle icon" ></i>
                  </button>
                  <ul className="dropdown-menu profile-ul" aria-labelledby="profileDropdown" >
                    <li><a className="dropdown-item" href="/profile">Profile <i className="bi bi-person-fill"></i></a></li>
                    

                    <li>
      
                      <button className="dropdown-item" onClick={() => setShowLogoutModal(true)} >
                         Logout <i className="bi bi-box-arrow-right"></i>
                      </button>
                    </li>
                  </ul>
                </li>

            </ul>
      </div>
    </nav>
    
    {/* Logout Confirmation Modal */}
    {showLogoutModal && (
      <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }} role="dialog" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Logout</h5>
              <button  type="button" className="btn-close" onClick={() => setShowLogoutModal(false)} ></button>
            </div>
            <div className="modal-body">
              <p style={{ color:"#000"}}>Are you sure you want to logout?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowLogoutModal(false)}  >
                Cancel
              </button>
              <button type="button" className="btn btn-danger" onClick={handleLogout} >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
    
    
    </>


  );
};

export default Navbar;