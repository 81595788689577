import React, { useEffect, useState } from 'react';
import './Tickets.css'; // Scoped CSS file
import { useAuth } from '../../contexts/AuthProvider';
import ticketService from '../../services/ticketsService';
import { useNavigate } from 'react-router-dom';

const Tickets = () => {
  const { auth } = useAuth();
  const [myTickets, setMyTickets] = useState([]);
  const [openTickets, setOpenTickets] = useState([]);
  const [otherTickets, setOtherTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoading(true);
        const ticketsData = await ticketService.getAllTickets();
        if (!ticketsData.valid) throw new Error(ticketsData.error);

        setMyTickets(ticketsData.myTickets || []);
        setOpenTickets(ticketsData.openTickets || []);
        setOtherTickets(ticketsData.otherTickets || []);
      } catch (err) {
        setError(err.message || 'Failed to load tickets.');
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, []);

  // Render a table for a given list of tickets
  const renderTicketsTable = (tickets) => {
    if (tickets.length === 0) return null;

    return (
      <table className="tickets-table">
        <thead>
          <tr>
            <th>Ticket ID</th>
            <th>Applicant</th>
            <th>Created By</th>
            <th>Date</th>
            <th>Promo Code</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) => (
            <tr
              key={ticket.ticket_id}
              className="tickets-table-row"
              onClick={() => navigate(`/tickets/${ticket.ticket_id}`)}
            >
              <td>#{ticket.ticket_id}</td>
              <td>{ticket.applicant_name}</td>
              <td>{ticket.created_by}</td>
              <td>{new Date(ticket.created_on).toLocaleDateString()}</td>
              <td>{ticket.promo_code || 'N/A'}</td> {/* Assuming promo_code might be in ticket data */}
              <td>
                <span
                  className={`tickets-status-badge status-${ticket.status
                    .toLowerCase()
                    .replace(/ /g, '-')}`}
                >
                  {ticket.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="tickets-page-container">
      <h1 className="tickets-page-title">Tickets</h1>

      {loading && <p className="tickets-loading">Loading tickets...</p>}
      {error && <p className="tickets-error">{error}</p>}

      {!loading && !error && (
        <>
          {auth.role === 'Client' && (
            <>
              <section className="tickets-section">
                <h3 className="tickets-section-title">Ongoing Tickets</h3>
                {openTickets.length > 0 ? (
                  renderTicketsTable(openTickets)
                ) : (
                  <p className="tickets-empty">No ongoing tickets found.</p>
                )}
              </section>

              <section className="tickets-section">
                <h3 className="tickets-section-title">Other Tickets</h3>
                {otherTickets.length > 0 ? (
                  renderTicketsTable(otherTickets)
                ) : (
                  <p className="tickets-empty">No other tickets found.</p>
                )}
              </section>
            </>
          )}

          {(auth.role === 'StellarUser' || auth.role === 'Admin') && (
            <>
              <section className="tickets-section">
                <h3 className="tickets-section-title">My Tickets</h3>
                {myTickets.length > 0 ? (
                  renderTicketsTable(myTickets)
                ) : (
                  <p className="tickets-empty">No tickets assigned to you.</p>
                )}
              </section>

              <section className="tickets-section">
                <h3 className="tickets-section-title">Open Tickets</h3>
                {openTickets.length > 0 ? (
                  renderTicketsTable(openTickets)
                ) : (
                  <p className="tickets-empty">No open tickets found.</p>
                )}
              </section>

              <section className="tickets-section">
                <h3 className="tickets-section-title">Other Tickets</h3>
                {otherTickets.length > 0 ? (
                  renderTicketsTable(otherTickets)
                ) : (
                  <p className="tickets-empty">No other tickets found.</p>
                )}
              </section>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Tickets;