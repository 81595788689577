// src/services/adminService.js
import axiosInstance from './axiosInstance';

const adminService = {
  getAllUsers: async () => {
    const response = await axiosInstance.get('/api/users');
    return response.data;
  },

  getGroupedUsers: async () => {
    const response = await axiosInstance.get('/api/users/grouped');
    return response.data;
  },

  getUserByEmail: async (email) => {
    const response = await axiosInstance.get(`/api/users/${encodeURIComponent(email)}`);
    return response.data;
  },
  addUser: async (user) => {
    const response = await axiosInstance.post('/api/users', user);
    return response.data;
  },  
  
  getAllDomains: async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          { id: 1, name: "Credit Union Of New Jersey" },
          { id: 2, name: "BELCO Auto" },
          { id: 3, name: "OECU" },
          { id: 4, name: "Ohio Valley Credit Union" },
          { id: 5, name: "Tremont Credit Union" },
        ]);
      }, 1000);
    });
  },
};

export default adminService;
