import axiosInstance from './axiosInstance';

const API_URL ='';

const authService = {
  /**
   * Login API: Handles email and password authentication
   * @param {Object} credentials - { email, password }
   * @returns {Promise} - API response
   */
  login: async (credentials) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/auth/login`, credentials);
      return { ...response.data, valid: true };
    } catch (error) {
      return {
        valid: false,
        error: error.response?.data?.error || 'An unknown error occurred',
      };
    }
  },

  /**
   * Verify MFA API: Validates OTP
   * @param {Object} payload - { mfa_token, mfa_code }
   * @returns {Promise} - API response
   */
  verifyMfa: async (payload) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/auth/verify-mfa`, payload);
      console.log("youu")

      return { ...response.data, valid: true };
    } catch (error) {
      console.log("youu")
        return {
            valid: false,
            error: error?.response?.data?.error || 'An error occurred while verifying MFA',
          };
    }
  },

  /**
   * Forget Password API: Sends reset password link to the user's email
   * @param {Object} payload - { email }
   * @returns {Promise} - API response
   */
  forgetPassword: async (payload) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/auth/forget-password`, payload);
      return { ...response.data, valid: true };
    } catch (error) {
      return {
        valid: false,
        error: error?.response?.data?.error || 'An error occurred while processing your request',
      };
    }
  },

  /**
   * Get Token By Link API: Fetches MFA token by reset link ID
   * @param {string} id - The hashed MFA code from URL
   * @returns {Promise} - API response
   */
  getTokenByLink: async (id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/auth/getTokenByLink`, {
        params: { mfa_code_hashed: id },
      });
      return { ...response.data, valid: true };
    } catch (error) {
      return {
        valid: false,
        error: error?.response?.data?.error || 'Invalid or expired reset link.',
      };
    }
  },

  /**
   * Update Password API: Updates the user's password using reset link
   * @param {Object} payload - { token, hashed_code, email, new_password }
   * @returns {Promise} - API response
   */
  updatePassword: async (payload) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/auth/updatePassword`, payload);
      return { ...response.data, valid: true };
    } catch (error) {
      return {
        valid: false,
        error: error?.response?.data?.error || 'An error occurred while updating the password',
      };
    }
  },
  
};

export default authService;
