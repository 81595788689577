import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonCard from './PersonCard';
import './People.css';
import adminService from '../../services/adminService';

const People = () => {
  const navigate = useNavigate();
  const [grouped, setGrouped] = useState({
    admins: [],
    activeSalrefiEmployees: [],
    activeClients: [],
    inactiveEmployees: []
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGrouped = async () => {
      try {
        const data = await adminService.getGroupedUsers();
        setGrouped(data);
      } catch (err) {
        console.error('Failed to fetch grouped users:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchGrouped();
  }, []);

  const handleMakeAdmin = (id) => {
    alert(`Make Admin clicked for user ID: ${id}`);
  };

  const handleChangePassword = (id) => {
    alert(`Change Password clicked for user ID: ${id}`);
  };

  if (loading) return <div className="loading">Loading users...</div>;

  return (
    <div className="people">
      <div className="people-container container">
        <div className="header d-flex justify-content-between align-items-center">
          <h2 className="text-center mb-4">Manage People</h2>
          <button className="btn btn-primary" onClick={() => navigate('/admin/addPerson')}>
            + Add Person
          </button>
        </div>

        <UserSection title="Admins" users={grouped.admins} />
        <UserSection title="Salrefi Employees (Active)" users={grouped.activeSalrefiEmployees} />
        <UserSection title="Clients (Active)" users={grouped.activeClients} />
        <UserSection title="Inactive Employees" users={grouped.inactiveEmployees} />
      </div>
    </div>
  );
};

const UserSection = ({ title, users }) => {
  if (!users.length) return null;
  return (
    <div className="section">
      <h4 className="section-title">{title}</h4>
      <div className="people-list">
        {users.map((user) => (
          <PersonCard key={user.id} person={user} />
        ))}
      </div>
    </div>
  );
};

export default People;
