import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ResetPassword.css";
import authService from "../services/authService";


const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State variables
  const [mfaToken, setMfaToken] = useState(null);
  const [fullName, setFullName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // Validation states
  const [passwordValidation, setPasswordValidation] = useState({
    uppercase: false,
    number: false,
    match: false,
    specialchar: false,
    minimum: false,
  });
  const [touched, setTouched] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  // Fetch token on load
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await authService.getTokenByLink(id);
        if (response.valid) {
          setMfaToken(response.mfa_token);
          setFullName(response.full_name);
          setMessage(response.message);
        } else {
          setError(response.error);
        }
      } catch (err) {
        setError("Invalid or expired reset link.");
      }
      setLoading(false);
    };
    fetchToken();
  }, [id]);

  // Password validation logic
  const validatePassword = (password) => {
    setPasswordValidation((prev) => ({
      ...prev,
      uppercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      match: password === confirmPassword,
      minimum: password.length >= 8,
      specialchar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    }));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
    if (!touched.newPassword) setTouched({ ...touched, newPassword: true });
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordValidation((prev) => ({ ...prev, match: value === newPassword }));
    if (!touched.confirmPassword) setTouched({ ...touched, confirmPassword: true });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    if (!passwordValidation.match || Object.values(passwordValidation).includes(false)) {
      setError("Please meet all password requirements before proceeding.");
      return;
    }

    try {
      const payload = {
        token: mfaToken,
        hashed_code: id,
        email: fullName,
        new_password: newPassword,
      };

      const response = await authService.updatePassword(payload);
      if (response.valid) {
        setMessage("Password updated successfully! Redirecting to login...");
        setRedirecting(true);
        setTimeout(() => navigate("/login"), 3000); // Redirect to login after 3 seconds
      } else {
        setError(response.error);
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="reset">
      <div className="reset-container d-flex flex-column align-items-center justify-content-center vh-100">
        <div className="card reset-card shadow-lg p-4">
          <div className="text-center">
            <img src="/logo.png" alt="Logo" className="mb-3 logo" />
            <h3 className="fw-bold">Reset Your Password</h3>
            {loading ? (
              <p>Loading...</p>
            ) : message ? (
              <p className="text-success">{message}</p>
            ) : (
              <p className="text-muted">Hello, {fullName}</p>
            )}
          </div>

          {!loading && !error && !redirecting && (
            <form onSubmit={handleSubmit} className="mt-3">
              <div className="mb-3 position-relative">
                <label className="form-label fw-bold">New Password</label>
                <div className="input-group position-relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  required
                />
             <i
            className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"} password-toggle`}
            onClick={togglePasswordVisibility}
            aria-hidden="true"
          ></i>
          </div>
                <ul className="password-requirements">
                  {touched.newPassword && (
                    <>
                      <li className={passwordValidation.minimum ? "valid" : "invalid"}>
                        Minimum 8 characters
                      </li>
                      <li className={passwordValidation.uppercase ? "valid" : "invalid"}>
                        At least one uppercase letter
                      </li>
                      <li className={passwordValidation.number ? "valid" : "invalid"}>
                        At least one number
                      </li>
                      <li className={passwordValidation.specialchar ? "valid" : "invalid"}>
                        At least one special character
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div className="mb-3 position-relative">
                <label className="form-label fw-bold">Confirm New Password</label>
                <div className="input-group position-relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
                <i
    className={`fa ${confirmPasswordVisible ? "fa-eye-slash" : "fa-eye"} password-toggle`}
    onClick={toggleConfirmPasswordVisibility}
    aria-hidden="true"
  ></i>
  </div>
                {touched.confirmPassword && (
                  <p className={passwordValidation.match ? "text-success" : "text-danger"}>
                    {passwordValidation.match ? "Passwords match" : "Passwords do not match"}
                  </p>
                )}
              </div>

              <button
                className="btn btn-primary w-100 fw-bold"
                type="submit"
                disabled={!passwordValidation.match || Object.values(passwordValidation).includes(false)}
              >
                Reset Password
              </button>
            </form>
          )}

          {error && (
            <div className="alert alert-danger mt-3">
              {error}
              <br />
              <div className="mt-2">
                <a href="/forgot-password" className="text-decoration-none">
                  Go back to Forgot Password
                </a>
                <span className="mx-2">|</span>
                <button
                  className="btn btn-link p-0 text-decoration-none"
                  onClick={() => window.location.reload()}
                >
                  Retry
                </button>
              </div>
            </div>
          )}

          {redirecting && <p className="text-info mt-3">Redirecting to login...</p>}
        </div>
        <footer className="mt-auto text-center text-muted small">
          <p>&copy; {new Date().getFullYear()} Stellar Financial Group LLC. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default ResetPassword;
