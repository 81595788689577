import React, { useState } from "react";
import "./Dashboard.css";

const FeaturesSection = () => {
  const features = [
    {
      id: "login",
      title: "MFA Login",
      text: "Secure your account with Multi-Factor Authentication.",
      image: '/MFA_laptop.png',
    },
    {
      id: "filtering",
      title: "Filtering Apps ",
      text: "Filter apps based on Domains, Date Range, and App Type.",
      image: "/filter_laptop.png",
    },
    {
      id: "searching",
      title: "Searching Apps ",
      text: "Use the search bar to find apps instantly by Name [first/last name], App ID.",
      image: "/search_laptop.png",
    },
    {
      id: "download",
      title: "Download CSV Report",
      text: "Export your data in a structured CSV format for easy analysis.",
      image: "/csv_laptop.png",
    },
    {
      id: "ticketing",
      title: "Ticketing System ",
      text: "If you face any issues or need more information about an applicant, claim the application and submit your request.",
      image: "/tickets_laptop.png",
    },
  ];

  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <div className="features-container">
      {/* Header */}
      <h2 className="feature-heading">NEW FEATURES </h2> 

      <h6 className="feature-subheading">We’ve added some great new features to our portal to improve your experience. Below, you’ll find a list of the latest updates, all designed to make it easier and more efficient to access and manage your applications.</h6>

      {/* Layout with feature list on left and image on right */}
      <div className="features-layout">
        {/* Left Side: Feature List */}
        <div className="features-list">
          {features.map((feature) => (
            <div
              key={feature.id}
              className={`feature-item ${selectedFeature.id === feature.id ? "active" : ""}`}
              onClick={() => setSelectedFeature(feature)}
            >
              <h3>{feature.title}</h3>
              <p>{feature.text}</p>
            </div>
          ))}
        </div>

        {/* Right Side: Dynamic Image */}
        <div className="feature-image">
          <img src={selectedFeature.image} alt={selectedFeature.title} />
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
