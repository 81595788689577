import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../../services/adminService';
import './AddPerson.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

const AddPerson = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    pass: '',
    isAdmin: false,
    isSalrefiEmployee: true,
    active: true,
    notify: false,
    notifyIncomplete: false,
  });

  const [showPass, setShowPass] = useState(false); // State to toggle password visibility
  const [errors, setErrors] = useState({}); // State to track validation errors

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === 'checkbox' ? checked : value,
    });
    // Clear error for the field being changed
    setErrors({ ...errors, [name]: '' });
  };

  const toggleShowPass = () => {
    setShowPass(!showPass);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.firstName) newErrors.firstName = 'First Name is required';
    if (!form.lastName) newErrors.lastName = 'Last Name is required';
    if (!form.email) newErrors.email = 'Email is required';
    if (!form.pass) newErrors.pass = 'Pass is required';
    // For checkboxes, we can enforce a specific state if needed
    // Here, I'm just ensuring they are explicitly set (true/false), but you can modify the logic
    if (form.isAdmin === undefined) newErrors.isAdmin = 'Admin status is required';
    if (form.isSalrefiEmployee === undefined) newErrors.isSalrefiEmployee = 'Salrefi Employee status is required';
    if (form.active === undefined) newErrors.active = 'Active status is required';
    if (form.notify === undefined) newErrors.notify = 'Notify status is required';
    if (form.notifyIncomplete === undefined) newErrors.notifyIncomplete = 'Notify Incomplete status is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      alert('Please fill in all required fields.');
      return;
    }

    const user = {
      first_name: form.firstName,
      last_name: form.lastName,
      email: form.email,
      password: form.pass,
      admin: form.isAdmin,
      sal: form.isSalrefiEmployee,
      active: form.active,
      notify: form.notify,
      notify_incomplete: form.notifyIncomplete,
    };

    try {
      await adminService.addUser(user);
      alert('User added successfully!');
      navigate('/admin/people');
    } catch (err) {
      console.error('Add user failed:', err);
      alert('Failed to add user.');
    }
  };

  return (
    <div className="add-person-container mt-5">
      <h2 className="text-center mb-4">Add New Person</h2>
      <form onSubmit={handleSubmit} className="card p-4 shadow">
        <div className="row">
          {/* LEFT SIDE: Input Fields */}
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">First Name <span className="text-danger">*</span></label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                value={form.firstName}
                onChange={handleChange}
                required
              />
              {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
            </div>

            <div className="mb-3">
              <label className="form-label">Last Name <span className="text-danger">*</span></label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                value={form.lastName}
                onChange={handleChange}
                required
              />
              {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
            </div>

            <div className="mb-3">
              <label className="form-label">Email <span className="text-danger">*</span></label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={form.email}
                onChange={handleChange}
                required
              />
              {errors.email && <small className="text-danger">{errors.email}</small>}
            </div>

            <div className="mb-3 position-relative">
              <label className="form-label">Password <span className="text-danger">*</span></label>
              <input
                type={showPass ? 'text' : 'password'}
                name="pass"
                className="form-control"
                value={form.pass}
                onChange={handleChange}
                required
              />
              <span className="eye-icon" onClick={toggleShowPass}>
                {showPass ? <FaEyeSlash /> : <FaEye />}
              </span>
              {errors.pass && <small className="text-danger">{errors.pass}</small>}
            </div>
          </div>

          {/* RIGHT SIDE: Toggles */}
          <div className="col-md-6">
            <div className="toggle-row">
              <label htmlFor="isAdmin">Admin <span className="text-danger">*</span></label>
              <input
                type="checkbox"
                className="form-check-input"
                id="isAdmin"
                name="isAdmin"
                checked={form.isAdmin}
                onChange={handleChange}
              />
              {errors.isAdmin && <small className="text-danger">{errors.isAdmin}</small>}
            </div>

            <div className="toggle-row">
              <label htmlFor="isSalrefiEmployee">Salrefi Employee? <span className="text-danger">*</span></label>
              <input
                type="checkbox"
                className="form-check-input"
                id="isSalrefiEmployee"
                name="isSalrefiEmployee"
                checked={form.isSalrefiEmployee}
                onChange={handleChange}
              />
              {errors.isSalrefiEmployee && <small className="text-danger">{errors.isSalrefiEmployee}</small>}
            </div>

            <div className="toggle-row">
              <label htmlFor="active">Active <span className="text-danger">*</span></label>
              <input
                type="checkbox"
                className="form-check-input"
                id="active"
                name="active"
                checked={form.active}
                onChange={handleChange}
              />
              {errors.active && <small className="text-danger">{errors.active}</small>}
            </div>

            <div className="toggle-row">
              <label htmlFor="notify">Notify <span className="text-danger">*</span></label>
              <input
                type="checkbox"
                className="form-check-input"
                id="notify"
                name="notify"
                checked={form.notify}
                onChange={handleChange}
              />
              {errors.notify && <small className="text-danger">{errors.notify}</small>}
            </div>

            <div className="toggle-row">
              <label htmlFor="notifyIncomplete">Notify Incomplete <span className="text-danger">*</span></label>
              <input
                type="checkbox"
                className="form-check-input"
                id="notifyIncomplete"
                name="notifyIncomplete"
                checked={form.notifyIncomplete}
                onChange={handleChange}
              />
              {errors.notifyIncomplete && <small className="text-danger">{errors.notifyIncomplete}</small>}
            </div>
          </div>
        </div>

        {/* CENTERED SUBMIT BUTTON */}
        <div className="submit-button-container">
          <button className="btn btn-success" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPerson;