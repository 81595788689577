import axiosInstance from './axiosInstance';
const API_URL = '';

const analyticsService = {
  getDomainStats: async (selectedCU = null, startDate = null, endDate = null, rangeType = 'day') => {
    try {
      const response = await axiosInstance.get(`${API_URL}/admin/stats`, {
        params: { startDate, endDate, rangeType },
        headers: selectedCU ? { 'selected-domain': selectedCU } : {},
      });
      return { data: response.data, valid: true };
    } catch (error) {
      return {
        valid: false,
        error: error.response?.data?.error || 'Unable to fetch application stats',
      };
    }
  },
};

export default analyticsService;