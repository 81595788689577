import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './ApplicationTicketData.css';

const ApplicationTicketData = ({ ticketData }) => {
  const navigate = useNavigate();

  // If `ticketData` is null, return nothing
  if (!ticketData) return null;

  // Status Colors Mapping
  const statusColors = {
    new: 'app-ticket-status-new',
    'in progress': 'app-ticket-status-in-progress',
    resolved: 'app-ticket-status-resolved',
    cancelled: 'app-ticket-status-cancelled',
    reopened: 'app-ticket-status-reopened',
    closed: 'app-ticket-status-closed',
  };

  // Format the date as "March 13th 2025 - 8:30 AM"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  };

  const formattedDate = formatDate(new Date(ticketData.created_on));

  return (
    <div className="app-ticket-container">
      <div className="app-ticket-table-wrapper">
        <table className="app-ticket-table">
          <thead>
            <tr>
              <th>Ticket ID</th>
              <th>Description</th>
              <th>Created By</th>
              <th>Date Created</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              className="app-ticket-table-row"
              onClick={() => navigate(`/tickets/${ticketData.ticket_id}`)}
            >
              <td>#{ticketData.ticket_id}</td>
              <td>{ticketData.description}</td>
              <td>{ticketData.created_by}</td>
              <td>{formattedDate}</td>
              <td>
                <span
                  className={`app-ticket-status-badge ${statusColors[ticketData.status.toLowerCase()]}`}
                >
                  {ticketData.status}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ApplicationTicketData.propTypes = {
  ticketData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    date_created: PropTypes.string.isRequired,
    created_by: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['new', 'in progress', 'resolved', 'cancelled', 'reopened', 'closed']).isRequired,
    application_id: PropTypes.string,
    ticket_id: PropTypes.string.isRequired,
  }),
};

export default ApplicationTicketData;