import React from 'react';
import './Pagination.css'; 

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleFirst = () => {
    onPageChange(1);
  };

  const handleLast = () => {
    onPageChange(totalPages);
  };

  return (
    <div className="pagination">
      <button className="page-button" disabled={currentPage === 1} onClick={handleFirst}><i className="bi bi-caret-left-fill"></i><i className="bi bi-caret-left-fill me-1"></i><span className="d-none d-md-inline">First</span></button>
      <button className="page-button" disabled={currentPage === 1} onClick={handlePrevious}><i className="bi bi-caret-left-fill me-1"></i><span className="d-none d-md-inline">Previous</span></button>
      <span className="page-info">
       &nbsp; Page {currentPage} of {totalPages} &nbsp;
      </span>
      <button className="page-button" disabled={currentPage === totalPages} onClick={handleNext}><span className="d-none d-md-inline">Next</span><i className="bi bi-caret-right-fill ms-1"></i></button>
      <button className="page-button" disabled={currentPage === totalPages} onClick={handleLast}><span className="d-none d-md-inline">Last</span><i className="bi bi-caret-right-fill ms-1"></i><i className="bi bi-caret-right-fill"></i></button>
    </div>
  );
};

export default Pagination;
