import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Filters from "./Filters";
import Pagination from "./Pagination";
import ApplicationCard from "./ApplicationCard";
import applicationService from "../../services/applicationService";
import "./Applications.css";
import Application from "../../models/Application";
import FilterCards from "./FilterCards";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [domains, setDomains] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    applicationType: "unclaimed",
    dateRange: { start: "", end: "" },
    domains: domains,
  });
  const [currentView, setCurrentView] = useState("");
  const [pageSize] = useState(10);
  const [error, setError] = useState(null); 
  const [loading, setLoading] = useState(false);
  
  // for download csv modal
  const [limit, setLimit] = useState(1000);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      domains: domains,
      applicationType: currentView,
    }));
  }, [domains, currentView]);
  


  useEffect(() => {
    const initialize = async () => {
      setError(null); 
      try {

        const domainsData = await applicationService.getDomains();
        if (!domainsData.valid) throw new Error(domainsData.error);

        setDomains(domainsData.data.domains);

        if (location.pathname === "/applications") {
          
          const data = await applicationService.getDefault();
          if (!data.valid) throw new Error(data.error);

          navigate(`/applications/${data.view}`, { replace: true });

        } else {
          const view = location.pathname.split("/").pop();
          let filters_new = {
            ...filters,
            applicationType: view,
            domains: domainsData.data.domains
          }
          await fetchApplications(view, 1, "", filters_new);
        }
    

      } catch (err) {
        setError(err.message || "Failed to load applications.");
      }
    };
    initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleRemoveFilter = (filterKey) => {
    if (filterKey === 'dateRangeStart') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: { ...prevFilters.dateRange, start: '' },
      }));

      fetchApplications(currentView, 1, searchQuery, {
        ...filters,
        dateRange: { ...filters.dateRange, start: '' },
      })
    } else if (filterKey === 'dateRangeEnd') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: { ...prevFilters.dateRange, end: '' },
      }));
      fetchApplications(currentView, 1, searchQuery, {
        ...filters,
        dateRange: { ...filters.dateRange, end: '' },
      })
    } else if (filterKey === 'searchQuery') {
      setSearchQuery('');
      setTempSearchQuery('');
      fetchApplications(currentView, 1, "", filters)
    }
  };
  

  const fetchApplications = async (view, pageIndex, searchTerm = searchQuery, appliedFilters = filters) => {
    setError(null);
    setLoading(true);
    try {
      const { applications, pagination, valid, error } = await applicationService.getByView( view, pageSize,  pageIndex - 1, searchTerm, appliedFilters);
      const apps =  applications.map((app) => new Application(app));

      if (!valid) throw new Error(error);

      setApplications(apps);
      setTotalPages(pagination.totalPages);
      setCurrentPage(pageIndex);
      setCurrentView(view);
    } catch (err) {
      console.log(err)
      setError(err.message || "Failed to load applications.");
    } finally {
      setLoading(false)
    }
  };

  const handlePageChange = async (page) => {
    await fetchApplications(currentView, page);
  };

  const handleSearch = async () => {
    setSearchQuery(tempSearchQuery);
    await fetchApplications(currentView, 1, tempSearchQuery);
  };

  const handleApplyFilters = async (newFilters) => {

    if(newFilters.applicationType !== currentView) {
      navigate(`/applications/${newFilters.applicationType}`, { replace: true });
      return;
    }
    setFilters(newFilters);
    await fetchApplications(currentView, 1, searchQuery, newFilters);
  };
     
  const handleExportCSV = async () => {
    const view = currentView 
    const dateRange = { start: startDate, end: endDate };

    const result = await applicationService.download_csv(view, limit, dateRange);

    if (result.valid) {
      console.log(result.data.data)
      const blob = new Blob([result.data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

    
      const a = document.createElement('a');
      a.href = url;
      a.download = 'unclaimed_applications.csv'; 
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
 
      console.error('Error downloading CSV:', result.error);
      alert('Failed to download CSV. Please try again.');
    }

    setShowModal(false);
  };
  
  
  return (
    <div className="applications-container">
      {loading && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
      {error ? ( 
        <div className="alert alert-danger" role="alert">
          {error} 
        </div>
      ) : (
        <>
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between mb-3">
            <h1 className="page-title mb-2 mb-md-0">Applications</h1>
            <button className="btn btn-success" onClick={() => setShowModal(true)}>
              <i className="bi bi-download me-2"></i> Export as CSV
            </button>
          </div>

         
          <div className={`modal fade ${showModal ? "show d-block" : ""}`} tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Export CSV</h5>
                  <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                </div>
                <div className="modal-body">

                  <div className="mb-5">
                    <div className="range">
                          <label className="form-label">Limit: <strong>{limit}</strong></label>
          
                          <div id="debt-amount-slider">
                            
                            <input type="radio"  name="debt-amount" id="1" value="10" onChange={(e) => setLimit(10)} />
                            <label htmlFor="1" data-debt-amount="10"></label>

                            <input type="radio" name="debt-amount" id="2" value="15" onChange={(e) => setLimit(15)} />
                            <label htmlFor="2" data-debt-amount="15"></label>

                            <input type="radio" name="debt-amount" id="3" value="25" onChange={(e) => setLimit(25)} />
                            <label htmlFor="3" data-debt-amount="25"></label>

                            <input type="radio" name="debt-amount" id="4" value="50" onChange={(e) => setLimit(50)} defaultChecked/>
                            <label htmlFor="4" data-debt-amount="50"></label>

                            <input type="radio" name="debt-amount" id="5" value="5000" onChange={(e) => setLimit(5000)} />
                            <label htmlFor="5" data-debt-amount="All"></label>

                          </div> 
                    </div>
                  </div>

                 
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Start Date:</label>
                      <input type="date"  className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>

        
                    <div className="mb-3 col-md-6">
                      <label className="form-label">End Date:</label>
                      <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                  </div>

                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Cancel
                  </button>
                  <button type="button" className="btn btn-success" onClick={handleExportCSV}>
                    <i className="bi bi-download me-2"></i> Export
                  </button>
                </div>
              </div>
            </div>
          </div>

          {showModal && <div className="modal-backdrop fade show"></div>}
          

          <div className="row mb-1">
            <div className="col-md-6 col-lg-8 mb-3 mb-md-0">
              <Filters filtersState = {filters} onSaveFilters={handleApplyFilters} domains = {domains}  />
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Search applications..." value={tempSearchQuery} onChange={(e) => setTempSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(); 
                    }
                  }} 
                />
                <button className="btn btn-primary" onClick={handleSearch}>
                  <i className="bi bi-search me-2"></i> Search
                </button>
              </div>
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-12 p-2">
              <FilterCards filters={filters} searchQuery={searchQuery} onRemoveFilter={handleRemoveFilter} />
            </div>
          </div>

          <div className="table-above">
            <table className="">
              <thead >
                <tr className="tr">
                  <th ></th>
                  <th className="fw-bold" >Name <br />City, State</th>
                  <th className="fw-bold">Phone <br /> Email</th>
                  <th className="fw-bold text-nowrap">Submitted At (EST) <br /> Domain</th>
                  <th>Updated By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((app) => (
                  <ApplicationCard key={app.guid} application={app} view= {currentView}/>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-md-center justify-content-center mb-4">
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
        </>
       )} 
    </div>
  );
};

export default Applications;
