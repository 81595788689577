import React, { useState, useEffect } from "react";
import { Container, Accordion, Row, Col } from "react-bootstrap";
import "./Dashboard.css";
import FeaturesSection from "./FeaturesSection";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import  Navbar  from "./Navbar";

const Dashboard = () => {
  const [selectedItem, setSelectedItem] = useState("filtering");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Replace with actual login state
  const navigate = useNavigate();
  const { auth } = useAuth();
  const isAdmin = auth?.role?.toLowerCase() === "admin";

  const faqs =[
    {
      question: "How do I filter apps?",
      answer: "Go to the <b>Filters section</b> -> select criteria, and apply.",
    },
    {
      question: "How do I download a CSV file?",
      answer: "Click <b>Export CSV</b>, and the file will be generated based on your selection.",
    },
    {
      question: "What should I do if I encounter issues with an applicant?",
      answer: [
        "If you need more information about the applicant, <b>claim the application</b>.",
        "Click the <b>Help button</b> in the top right corner and submit your request.",
        "Our team will assist you as soon as possible.",
        "Track all conversations and updates in the <b>Tickets</b> section of the portal.",
        "Communication can take place both via email and within the portal for your convenience, Using <b>Reply All</b> to maintain the conversation history."
      ],
    },
    {
      question: "How do I reset my password if I can't log in?",
      answer: "If you can't log in, click <b>Forgot password</b> and enter your authorized email. You'll receive an <b>OTP</b> (one time passcode) via email; enter this <b>OTP</b> (one time passcode) to set a new password and log in.",
    },
    
    {
      question: "What types of issues can I raise tickets for?",
      answer: "You can raise a ticket for issues related to individual applications, including <b>missing information</b> or clarifications needed about applicants.",
    },
    
    {
      question: "Can I reopen or cancel a ticket?",
      answer: "Yes, you can <b>REOPEN</b> a resolved ticket if further clarification is needed. If a ticket was created mistakenly, you can <b>CANCEL</b> it anytime before a representative starts working on it.",
    },
    {
      question: "What do different ticket statuses mean?",
      answer: [
        "<b>New</b> (ticket submitted)",
        "<b>In Progress</b> (ticket is being handled)",
        "<b>Resolved</b> (issue addressed)",
        "<b>Cancelled</b> (request cancelled)",
        "<b>Reopened</b> (further action needed)",
        "<b>Closed</b> (completed, no further action expected)"
      ],
    },
    {
      question: "Can I download all claimed, unclaimed, and my apps data in one CSV?",
      answer: "No, downloads are only available separately for <b>claimed</b> and <b>unclaimed</b> apps, not combined.",
    },
    {
      question: "Who can I contact for more support regarding the new portal?",
      answer: "For further assistance, contact <b>callcenter@stellarfg.com</b>.",
    },
    {
      question: "How many tickets can I raise at a time?",
      answer: "You can raise only one ticket at a time for each application. The tickets themselves can have multiple request within them. ",
    },
  ];
  useEffect(() => {
    // Select all sections with the animate-section class
    const sections = document.querySelectorAll(".animate-section");

    // Create an Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add the is-visible class when the section is in view
            entry.target.classList.add("is-visible");
            // Optional: Stop observing after animation triggers (uncomment if desired)
             observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.2, // Trigger when 20% of the section is visible
      }
    );

    // Import the useAuth context

    // Use the useAuth context to check if the user is authenticated
    
    

    // Observe each section
    sections.forEach((section) => observer.observe(section));

    // Cleanup observer on component unmount
    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []); // Empty dependency array to run once on mount

  
  useEffect(() => {
    setIsLoggedIn(!!auth); // Set isLoggedIn based on whether the user exists
  }, [auth]);

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <Container fluid className="dashboard-container">
      {/* <div>
  
        <div>
          {!isLoggedIn && (
            <button onClick={handleLoginRedirect} className="btn btn-primary">
              Go to Login
            </button>
          )}
        </div>
      </div> */}

      
      {isLoggedIn && <Navbar isAdmin={isAdmin} />}

      <div className="welcome-section animate-section">
      <video autoPlay loop muted playsInline className="bg-video">
        <source src="/dashboard_vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      <div className="welcome-overlay">
        <h3>
          <span className="highlight">
           <i> New & Improved </i>
          </span>
        </h3>
        <h1><b className="stellar-color"> STELLAR APPLICATION PORTAL </b></h1>
        <p>Now faster, smoother and more intuitive.</p>
        {/* Dynamic Button */}
        {isLoggedIn ? (
          <button onClick={() => navigate("/home")} className="btn btn-primary">
            View Applications
          </button>
        ) : (
          <button onClick={handleLoginRedirect} className="btn btn-primary">
            Go to Login
          </button>
        )}
      </div>
    </div>

      <div className="features-section animate-section" >
        
        <div className="content-box">
        
         <FeaturesSection />
        </div>
      </div>

      <div className="faq-sect animate-section ">
      <Container className="faq-section">
      <h2 className="faq-head">FREQUENTLY ASKED QUESTIONS</h2>
      <p className="faq-subtext">Our FAQs offers clear guidance on navigating throughout the portal. Below, you'll find answers to common questions about accessing and managing your applications. If you need further assistance, our support team is ready to help.</p>
      <Row>
        {/* Left Column */}
        <Col md={6}>
          <Accordion flush>
            {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>
                  {Array.isArray(faq.answer) ? (
                    <ul>
                      {faq.answer.map((point, idx) => (
                        <li key={idx} dangerouslySetInnerHTML={{ __html: point }}></li>
                      ))}
                    </ul>
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>

        {/* Right Column */}
        <Col md={6}>
          <Accordion flush>
            {faqs.slice(Math.ceil(faqs.length / 2)).map((faq, index) => (
              <Accordion.Item eventKey={(index + Math.ceil(faqs.length / 2)).toString()} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>
                  {Array.isArray(faq.answer) ? (
                    <ul>
                      {faq.answer.map((point, idx) => (
                        <li key={idx} dangerouslySetInnerHTML={{ __html: point }}></li>
                      ))}
                    </ul>
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
      </div>

      <div className="contact-section">
        <div className="contact-overlay"> 
          <p>&copy; 2025 The Stellar Financial Group LLC</p>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;