import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ApplicationTicketRaise = ({ onConfirm, onCancel }) => {
  const [formData, setFormData] = useState({
    description: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleConfirm = () => {
    if (formData.description.trim()) {
      onConfirm(formData); 
    } else {
      alert('Please fill out the description field.');
    }
  };

  return (
    <div className="card p-4 shadow-lg">
      <h3 className="text-primary text-center mb-4">Raise a Ticket</h3>
      <form>
        

        {/* Description Field */}
        <div className="form-group mb-4">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea   id="description" name="description" className="form-control" rows="4"  value={formData.description} onChange={handleInputChange} placeholder="Enter the ticket description"></textarea>
        </div>

        {/* Buttons */}
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-secondary me-2" onClick={onCancel}> Cancel </button>
          <button type="button" className="btn btn-primary" onClick={handleConfirm}>Confirm</button>
        </div>
      </form>
    </div>
  );
};

ApplicationTicketRaise.propTypes = {
  onConfirm: PropTypes.func.isRequired, 
  onCancel: PropTypes.func.isRequired,
};

export default ApplicationTicketRaise;
