import React, { useEffect, useState } from "react";
import './Filters.css';

const Filters = ({ filtersState, onSaveFilters, domains }) => { 
  const [filters, setFilters] = useState(filtersState);
  const [previousState, setPreviousState] = useState(filtersState);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setFilters(filtersState);
    setPreviousState(filtersState);
  }, [filtersState]);

  const handleToggleAllDomains = (selectAll) => {
    setFilters((prev) => ({
      ...prev,
      domains: selectAll ? [...domains] : [],
    }));
  };

  const handleSave = () => {
    onSaveFilters(filters);
    setPreviousState(filters);
    setShowDropdown(false);
  };

  const handleCancel = () => {
    setFilters(previousState);
    setShowDropdown(false);
  };

  return (
    <div className="filters dropdown">
      {/* Toggle Button */}
      <div className="d-grid d-md-inline">
        <button className="btn btn-primary dropdown-toggle" type="button" id="filtersDropdownButton" aria-expanded={showDropdown} onClick={() => setShowDropdown((prev) => !prev)} >
          Filters <i className="bi bi-funnel-fill mx-2"></i>
        </button>
      </div>


      {/* Dropdown Content */}
      <div
        className={`dropdown-menu p-3 shadow ${showDropdown ? "show" : ""}`}
        aria-labelledby="filtersDropdownButton"
      >

        

        {/* Application Type */}
        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <label className="form-label fw-bold">Application Type</label>

            <button className="btn-close" style={{ color: 'red' }} onClick={() => setShowDropdown(false)}></button>
          </div>
          <select
            className="form-select"
            value={filters.applicationType}
            onChange={(e) =>
              setFilters({ ...filters, applicationType: e.target.value })
            }
          >
            <option value="unclaimed">Unclaimed Applications</option>
            <option value="mine">My Applications</option>
            <option value="all">All Applications</option>
          </select>
        </div>

        {/* Date Range */}
        <div className="form-group">
          <label className="form-label fw-bold">Date Range</label>
          <div className="d-flex gap-2">
            <div className="mb-3">
              <label className="form-label small mb-0" htmlFor="startdate-filter">
                Start
              </label>
              <input type="date" className="form-control" id="startdate-filter" value={filters.dateRange.start}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    dateRange: { ...filters.dateRange, start: e.target.value },
                  })
                }
              />
            </div>
            <div className="form-group ">
              <label className="form-label small mb-0" htmlFor="enddate-filter">
                End
              </label>
              <input type="date" className="form-control" id="enddate-filter" value={filters.dateRange.end}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    dateRange: { ...filters.dateRange, end: e.target.value },
                  })
                }
              />
            </div>
          </div>
        </div>

        {/* Domains */}
        <div className="mb-3">
          <label className="form-label fw-bold">Domains</label>
          <div className="form-check mb-1 d-flex align-items-center">
            <input className="form-check-input"  type="checkbox" id="selectAllDomains"
              checked={
                domains.length > 0 &&
                filters.domains.length === domains.length
              }
              onChange={(e) => handleToggleAllDomains(e.target.checked)}
            />
            <label className="form-check-label ms-2" htmlFor="selectAllDomains">
              Select All
            </label>
          </div>

          {/* Add scrollable container for domains */}
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            {domains.map((domain, idx) => (
              <div key={idx} className="form-check mb-1 d-flex align-items-center">
                <input className="form-check-input"  type="checkbox"
                  id={`domain-${idx}`}
                  checked={filters.domains.includes(domain)}
                  onChange={(e) => {
                    const updatedDomains = e.target.checked
                      ? [...filters.domains, domain]
                      : filters.domains.filter((d) => d !== domain);
                    setFilters({ ...filters, domains: updatedDomains });
                  }}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor={`domain-${idx}`}
                >
                  {domain}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={handleSave}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
