import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./AdminDashboard.css";
import analyticsService from "../../services/analyticsService";

const AdminDashboard = () => {
  const [fullData, setFullData] = useState({ domainWise: [], dateWise: [] });
  const [filteredData, setFilteredData] = useState([]);
  const [rangeType, setRangeType] = useState("day");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCU, setSelectedCU] = useState("all");

  const fetchStats = async () => {
    try {
      const response = await analyticsService.getDomainStats(
        selectedCU === "all" ? null : selectedCU,
        startDate || null,
        endDate || null,
        rangeType
      );
      console.log("API Response:", response);
      if (response.valid) {
        const domainWise = (response.data.domainWise || []).map((item) => ({
          domain: item.domain,
          complete: Number(item.complete),
          incomplete: Number(item.incomplete),
        }));
        const dateWise = (response.data.dateWise || []).map((item) => ({
          domain: item.domain,
          date: item.date,
          complete: Number(item.complete),
          incomplete: Number(item.incomplete),
        }));
        console.log("DomainWise:", domainWise);
        console.log("DateWise:", dateWise);
        console.log("Filters:", { selectedCU, startDate, endDate, rangeType });

        const newFullData = { domainWise: [...domainWise], dateWise: [...dateWise] };
        setFullData(newFullData);
        applyFilters(newFullData);
      } else {
        console.error("Invalid response:", response.error);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [startDate, endDate, selectedCU, rangeType]);

  const applyFilters = ({ domainWise, dateWise }) => {
    const useDateWise = startDate && endDate;
    let filtered = useDateWise ? [...dateWise] : [...domainWise];

    if (selectedCU !== "all") {
      filtered = filtered.filter((item) => item.domain === selectedCU);
    }

    if (useDateWise) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    console.log("Filtered Data:", filtered);
    setFilteredData(filtered.length ? filtered : []);
  };

  useEffect(() => {
    applyFilters(fullData);
  }, [fullData, selectedCU, startDate, endDate, rangeType]);

  const getTotal = (type) => {
    const total = filteredData.reduce((acc, cur) => acc + Number(cur[type]), 0);
    console.log(`Total ${type}:`, total);
    return total;
  };

  const resetFilters = () => {
    setRangeType("day");
    setSelectedCU("all");
    setStartDate("");
    setEndDate("");
  };

  const barChartData = {
    labels: filteredData.map((entry) => (startDate && endDate ? entry.date : entry.domain)),
    datasets: [
      {
        label: "Complete Applications",
        data: filteredData.map((entry) => Number(entry.complete)),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Incomplete Applications",
        data: filteredData.map((entry) => Number(entry.incomplete)),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
          callback: function (value) {
            return Number.isInteger(value) ? value : "";
          },
        },
      },
      x: {
        title: {
          display: true,
          text: startDate && endDate ? `${rangeType.charAt(0).toUpperCase() + rangeType.slice(1)}` : "Credit Union",
        },
      },
    },
    plugins: {
      legend: { display: true, position: "top" },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            const datasetIndex = tooltipItems[0].datasetIndex;
            const entry = filteredData[index];
            if (!entry) return "N/A";

            const domain = entry.domain;
            const date = entry.date || "All Time";
            const countType = datasetIndex === 0 ? "Complete" : "Incomplete";
            const count = datasetIndex === 0 ? entry.complete : entry.incomplete;

            return [
              `Credit Union: ${domain}`,
              `Date: ${date}`,
              `${countType}: ${count}`,
            ];
          },
          label: () => "",
        },
      },
    },
  };

  return (
    <div className="admin-dashboard-container">
      <h3 className="admin-dashboard-title">Application Statistics</h3>
      <div className="row admin-dashboard-filter">
        <div className="col-md-6">
          <label>Credit Union:</label>
          <select
            className="form-control"
            value={selectedCU}
            onChange={(e) => setSelectedCU(e.target.value)}
            aria-label="Select Credit Union"
          >
            <option value="all">All Credit Unions</option>
            {[...new Set(fullData.domainWise.map((item) => item.domain))].map((domain, index) => (
              <option key={index} value={domain}>
                {domain}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <label>Start Date:</label>
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            aria-label="Start Date"
          />
        </div>
        <div className="col-md-3">
          <label>End Date:</label>
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            aria-label="End Date"
          />
        </div>
        <div className="col-md-12 mt-3 text-center">
          <button
            className="btn btn-secondary"
            onClick={resetFilters}
            aria-label="Reset Filters"
          >
            Reset Filters
          </button>
        </div>
      </div>
      <div className="row text-center mb-4">
        <div className="col-md-6">
          <div className="admin-dashboard-chart p-3">
            <h5>Total Complete Applications</h5>
            <h3 style={{ color: "#27ae60" }}>{getTotal("complete")}</h3>
          </div>
        </div>
        <div className="col-md-6">
          <div className="admin-dashboard-chart p-3">
            <h5>Total Incomplete Applications</h5>
            <h3 style={{ color: "#e74c3c" }}>{getTotal("incomplete")}</h3>
          </div>
        </div>
      </div>
      <div className="admin-dashboard-chart">
        <h5>
          {startDate && endDate
            ? `${rangeType.charAt(0).toUpperCase() + rangeType.slice(1)}ly Application Statistics`
            : "Application Statistics by Credit Union"}
        </h5>
        {filteredData.length > 0 ? (
          <Bar
            key={`${selectedCU}-${startDate}-${endDate}-${rangeType}`}
            data={barChartData}
            options={chartOptions}
          />
        ) : startDate && endDate ? (
          <p className="text-center mt-3">
            Our database doesn’t have any apps for your selected time period.
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default AdminDashboard;