import React from 'react';
import "./FilterCards.css";

const FilterCards = ({ filters, searchQuery, onRemoveFilter }) => {
  return (
    <div className="filter-cards-container">
  {filters.dateRange.start && (
    <div className="filter-card">
      <span>Starting from: {filters.dateRange.start}</span>
      <button onClick={() => onRemoveFilter('dateRangeStart')}><i className="bi bi-x-lg"></i></button>
    </div>
  )}
  {filters.dateRange.end && (
    <div className="filter-card">
      <span>Ending at: {filters.dateRange.end}</span>
      <button onClick={() => onRemoveFilter('dateRangeEnd')}>X</button>
    </div>
  )}
  {searchQuery && (
    <div className="filter-card">
      <span>Search: {searchQuery}</span>
      <button onClick={() => onRemoveFilter('searchQuery')}>X</button>
    </div>
  )}
</div>

  );
};

export default FilterCards;
