import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom"; 
import "./ApplicationCard.css";
import applicationService from "../../services/applicationService";
import { useAuth } from "../../contexts/AuthProvider";

const ApplicationCard = ({ application, view }) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const role = auth.role;

  // State for controlling modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGuid, setSelectedGuid] = useState(null);

  const handleViewClick = () => {
    navigate(`/applications/view/${application.guid}?view=${view}`); 
  };

  const openModal = (guid) => {
    setSelectedGuid(guid);  
    setIsModalVisible(true); 
  };

  const closeModal = () => {
    setSelectedGuid(null);
    setIsModalVisible(false); 
  };

  const confirmNotify = async () => {
    if (selectedGuid) {
      await notify(selectedGuid);
      closeModal(); 
    }
  };

  const notify = async (guid) => {
    const response = await applicationService.notify(guid);
    if (response.valid) {
      alert(`Notification sent: ${response.message}`);
    } else {
      alert(`Error: ${response.error}`);
    }
  };

  const modalContent = isModalVisible ? (
    <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Notification</h5>
            <button type="button" className="btn-close" onClick={closeModal}></button>
          </div>
          <div className="modal-body">
            <p style={{ color:"#000"}}>Are you sure you want to send a notification?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={confirmNotify}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <>
      <tr className="application-card application-row">
        <td className="text-center">
          <button className="btn btn-outline-success btn-sm text-uppercase" onClick={handleViewClick}>
            View
            <i className="bi bi-eye-fill ms-1"></i>
          </button>
        </td>
        <td>
          <span className="text-capitalize text-dark fw-bolder text-nowrap">{application.name}</span>
          <br />
          <span className="text-muted">{application.cityState}</span>
        </td>
        <td>
          <span className="text-gray">{application.phone}</span>
          <br />
          <a href={`mailto:${application.email}`} className="text-lowercase mt-1">
            {application.email}
          </a>
        </td>
        <td>
          <span className="text-gray">{application.submittedAt}</span>
          <br />
          <span className="text-muted fst-italic">{application.domain}</span>
        </td>
        <td className={application.claimed_by_full_name ? "text-capitalize" : "text-muted"}>
          {application.claimed_by_full_name ? application.claimed_by_full_name : "Not Updated"}
          <br />
          <span className="text-muted text-lowercase">{application.claimed_by_full_name ? application.claimed_by : ""}</span>
        </td>

        {application.status ? (
          <td>
            <span>{application.status}</span>
            <br />
            <span className="text-muted">{application.status_updated_at_formatted}</span>
          </td>
        ) : application.claimed_by ? (
          <td>
            <button className="btn table-btn btn-warning btn-sm">Update Status</button>
          </td>
        ) : (
          <td>
            <span className="badge table-badge bg-warning text-dark">Pending</span>
            {(role === "Admin" || role === "StellarUser") && (
              <button
                className="table-button btn btn-outline-secondary btn-sm ms-2"
                onClick={() => openModal(application.guid)}
              >
                Notify<i className="bi bi-bell-fill ms-1"></i>
              </button>
            )}
          </td>
        )}
      </tr>

      {/* Render the modal using ReactDOM.createPortal */}
      {ReactDOM.createPortal(modalContent, document.body)}
    </>
  );
};

export default ApplicationCard;
