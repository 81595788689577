import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import './HomePage.css';

import { useAuth } from "../contexts/AuthProvider";
import Applications from "../components/AllApplications/Applications";
import ApplicationView from "../components/ApplicationView/ApplicationView";
import Profile from "../components/Profile";
import AdminMain from "../components/Admin/AdminMain";
import RoleGuard from "../guards/RoleGuard";
import TicketView from "../components/Tickets/TicketView";
import Tickets from "../components/Tickets/Tickets";

const HomePage = () => {
  const { auth } = useAuth();
  const isAdmin = auth.role.toLowerCase() === "admin";
  const user = {
    firstName: auth.userDetails.first_name.toUpperCase(), 
    lastName: auth.userDetails.last_name.toUpperCase(), 
    role: auth.role,
  };

  return (
    <div className="homePage">
      <Navbar isAdmin={isAdmin} />
      <div className="homePageContent">
        <Routes>
          <Route path="/" element={<Home />} />
          
          <Route path="admin/*" element={<AdminMain />} />
          <Route path="profile" element={<Profile user={user} />} />
          <Route
            path="/applications/view/:id"
            element={<ApplicationView isAdmin={isAdmin} />}
          />
          <Route path="tickets" element={<Tickets />} />
          <Route path="tickets/:ticketId" element={<TicketView />} />
          <Route path="applications/*" element={<Applications />} />
          <Route
            path="applications/incomplete"
            element={
              <RoleGuard allowedRoles={["Admin", "StellarUser"]}>
                <Applications />
              </RoleGuard>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default HomePage;
