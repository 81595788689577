import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import authService from '../services/authService';
import { useAuth } from '../contexts/AuthProvider';

const Login = () => {
  const [step, setStep] = useState(1); // 1 = Email/Password, 2 = MFA
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']); // 6-digit OTP
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [mfaToken, setMfaToken] = useState(null);
  const [attemptsLeft, setAttemptsLeft] = useState(3); // Allow 3 attempts
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [documentContent, setDocumentContent] = useState(''); // Document content
  const { login } = useAuth();
  const navigate = useNavigate();

  // Fetch external document (e.g., Terms of Service) when modal opens
  const loadDocument = async () => {
    try {
      // Replace this URL with your actual external document URL or file path
      const response = await fetch('terms.html'); // Example URL
      const text = await response.text();
      setDocumentContent(text);
    } catch (err) {
      setDocumentContent('Error loading document. Please try again later.');
    }
  };

  const handleLogin = async () => {
    setError('');
    setMessage('');
    const response = await authService.login({ email, password });
    if (response.valid) {
      setMessage(response.message);
      setMfaToken(response.mfa_token);
      setStep(2);
      setAttemptsLeft(3);
    } else {
      setError(response.error);
    }
  };

  const handleMfaSubmit = async () => {
    setError('');
    setMessage('');
    const otpCode = otp.join('');
    const response = await authService.verifyMfa({ mfa_token: mfaToken, mfa_code: otpCode });
    if (response.valid) {
      login(response);
      setMessage(response.message);
      navigate('/dashboard');
    } else {
      if (attemptsLeft > 1) {
        setOtp(['', '', '', '', '', '']);
        setAttemptsLeft(attemptsLeft - 1);
        setError(`Incorrect OTP. You have ${attemptsLeft - 1} attempts left.`);
      } else {
        setError('Maximum attempts reached. Redirecting to login.');
        setTimeout(() => {
          setStep(1);
          setOtp(['', '', '', '', '', '']);
          setError('');
        }, 2000);
      }
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const updatedOtp = [...otp];
    if (value === '' || /\d/.test(value)) {
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleOtpPaste = (e) => {
    const pasteData = e.clipboardData.getData('text');
    if (!/^\d{6}$/.test(pasteData)) return;
    const updatedOtp = pasteData.split('').slice(0, 6);
    setOtp(updatedOtp);
    updatedOtp.forEach((digit, i) => {
      const input = document.getElementById(`otp-${i}`);
      if (input) input.value = digit;
    });
    document.getElementById('otp-5').focus();
  };

  const handleOtpKeyDown = (e, index) => {
    const updatedOtp = [...otp];
    if (e.key === 'Backspace') {
      if (otp[index]) {
        updatedOtp[index] = '';
        setOtp(updatedOtp);
      } else if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    loadDocument(); // Load document when modal opens
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDocumentContent(''); // Clear content when closing
  };

  return (
    <div className="login-page">
    {/* Banner for switching to old login */}
  <div className="banner text-center">
    <p>
      Prefer the old login experience?{' '}
      <button
        className="btn btn-link"
        onClick={() =>  window.location.href = '/old-portal/index.php'}
      >
        Switch to Old Login
      </button>
    </p>
  </div>
    <div className="overlay">
  
</div>
      <div className="login-container d-flex align-items-center justify-content-center">
        <div className="login-box">
          <div className="text-center mb-4">
            <img src="/logo.png" alt="Logo" className="logo-img" />
          </div>

          {message && <div className="alert alert-success">{message}</div>}
          {error && <div className="alert alert-danger">{error}</div>}

          {step === 1 && (
            <>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">User Name</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <a href="/forgot-password">Forgot Password?</a>
              </div>
              <div className="mb-3">
                <input type="checkbox" id="termsbox" className="termscheckbox" />
                <label htmlFor="termsbox" className="termsbox">
                  I have read and agree to the{' '}
                  <a href="#" onClick={openModal}>
                    terms of use
                  </a>
                </label>
              </div>
              <button type="button" onClick={handleLogin} className="btn btn-primary w-100 login-button">
                Login
              </button>
            </>
          )}

          {step === 2 && (
            <>
              <div className="mb-3 text-center">
                <label className="form-label">Enter 6-digit MFA Code</label>
                <div className="otp-inputs d-flex justify-content-center">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-${index}`}
                      type="text"
                      className="otp-box"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      onPaste={index === 0 ? handleOtpPaste : null}
                      maxLength={1}
                    />
                  ))}
                </div>
              </div>
              <button type="button" className="btn btn-primary w-100" onClick={handleMfaSubmit}>
                Verify
              </button>
            </>
          )}
          <div className="page text-center mt-3">
            <p>
              Have Any Questions? Check <a href="/dashboard">FAQ's</a>
            </p>
          </div>
        </div>
      </div>

      {/* Modal for Terms of Service */}
      {isModalOpen && (
        <div className="login-modal">
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="modal-close-btn" onClick={closeModal}>
                &times;
              </button>
              <h3>Terms of Use</h3>
              <div className="modal-document">
                <pre>{documentContent || 'Loading...'}</pre>
              </div>
            </div>
          </div>
        </div>
      )}


      <footer className="footer text-center text-white">
        <p>&copy; The Stellar Financial Group LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Login;