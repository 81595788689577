import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/authService";
import './ForgetPassword.css'

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // ✅ Hook for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    setError(null);
    
    try {
      const response = await authService.forgetPassword({ email });
      if (response.valid) {
        setMessage("Please check your email to reset your password.");
      } else {
        setError(response.error);
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <div className="forget">
      <div className="container d-flex flex-column align-items-center justify-content-center  vh-100">
        <div className="card shadow-lg p-4 mt-5" style={{ maxWidth: "450px", width: "100%" }}>
          <div className="text-center">
            <img src="/logo.png" alt="Logo" className="mb-3" style={{ width: "180px" }} />
            <h3 className="fw-bold">Forgot Password?</h3>
            <p className="text-muted">Enter your registered email to reset your password.</p>
          </div>

          <form onSubmit={handleSubmit} className="mt-3">
            <div className="mb-3">
              <label className="form-label fw-bold"><i className="bi bi-envelope-fill me-2"></i>Email Address</label>
              <input
                type="email"
                className="form-control p-2"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button className="btn btn-primary w-100 fw-bold" type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send Reset Password Mail"}
            </button>
          </form>

          {/* ✅ Success & Error Messages */}
          {message && <div className="alert alert-success mt-3">{message}</div>}
          {error && <div className="alert alert-danger mt-3">{error}</div>}

          {/* ✅ Back to Login Button */}
          <button
            className="btn btn-outline-secondary w-100 mt-3"
            onClick={() => navigate("/login")} // Redirects to login
          >
            Back to Login
          </button>
        </div>

        <div className="mt-4 text-center text-muted">
          <p><i className="bi bi-info-circle me-2"></i>Only registered users can reset passwords.</p>
        </div>

        <footer className="mt-auto text-center text-muted small">
          <p>&copy; {new Date().getFullYear()} Stellar Financial Group LLC. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default ForgetPassword;
