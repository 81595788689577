import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap'; 
import './TicketView.css';
import { useAuth } from '../../contexts/AuthProvider';
import ticketService from '../../services/ticketsService';

const TicketView = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();

  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { auth } = useAuth();

  useEffect(() => {  
    fetchTicketData();
  }, [ticketId]);

  const fetchTicketData = async () => {
    const ticketData = await ticketService.getTicketById(ticketId);
    if (!ticketData.valid) throw new Error(ticketData.error);
    setTicketData(ticketData);
    setStatus(ticketData.status);
    setLoading(false);
  };

  const handleShowModal = (newStatus) => {
    setSelectedStatus(newStatus);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleStatusChange = async () => {
    try {
      setIsProcessing(true);
      setShowModal(false);
      const response = await ticketService.updateStatus(ticketId, selectedStatus, ticketData.replyTo);
      if (!response.valid) throw new Error(response.error);
      else {
        toast.success("Updated status successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        await fetchTicketData();
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } finally {
      setIsProcessing(false);
      setInactive(true);
      setTimeout(() => {
        setInactive(false);
      }, 15000);
    }
  };

  const notifySuccess = () => {
    toast.success("Message sent successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });
  };

  const notifyError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });
  };

  const handleReply = async () => {
    if (newMessage.trim()) {
      try {
        const response = await ticketService.replyToThread(ticketId, ticketData.replyTo, newMessage.trim());
        if (response.valid) {
          notifySuccess();
          fetchTicketData();
          setInactive(true);
          setTimeout(() => {
            setInactive(false);
          }, 15000);
        } else {
          notifyError(response.error);
        }
        setNewMessage('');
      } catch (error) {
        notifyError(error.message);
      }
    } else {
      notifyError("Please add content in Reply message.");
    }
  };

  const renderStatusButtons = () => {
    const buttons = [];
    if (auth.role === 'Client') {
      if (status === 'resolved') {
        buttons.push(
          <button key="reopen" className={`btn btn-warning me-2 ${inactive ? 'disabled' : ''}`} onClick={() => handleShowModal('reopen')} disabled={isProcessing || inactive}> Reopen </button>
        );
        buttons.push(
          <button key="close" className={`btn btn-danger ${inactive ? 'disabled' : ''}`} onClick={() => handleShowModal('close')} disabled={isProcessing || inactive}> Close </button>
        );
      }
      buttons.push(
        <button key="cancel" className={`btn btn-danger me-2 ${inactive ? 'disabled' : ''}`} onClick={() => handleShowModal('cancel')} disabled={isProcessing || inactive}> Cancel </button>
      );
    } else if (auth.role === 'StellarUser' || auth.role === 'Admin') {
      if (status === 'new') {
        buttons.push(
          <button key="claim" className={`btn btn-primary me-2 ${inactive ? 'disabled' : ''}`} onClick={() => handleShowModal('claim')} disabled={isProcessing || inactive}> Claim </button>
        );
      }
      if (['new', 'in progress', 'reopened'].includes(status)) {
        buttons.push(
          <button key="cancel" className={`btn btn-danger me-2 ${inactive ? 'disabled' : ''}`} onClick={() => handleShowModal('cancel')} disabled={isProcessing || inactive}> Cancel </button>
        );
      }
      if (status === 'in progress' || status === 'reopened') {
        buttons.push(
          <button key="resolve" className={`btn btn-success ${inactive ? 'disabled' : ''}`} onClick={() => handleShowModal('resolve')} disabled={isProcessing || inactive}> Resolve </button>
        );
      }
    }
    return buttons;
  };

  const getMessageType = (message) => {
    const userEmail = auth.email?.toLowerCase();
    const senderPattern = new RegExp(`\\(${userEmail}\\)`);
    if (senderPattern.test(message.content.toLowerCase())) {
      return 'sent';
    }
    return 'received';
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <img
          src="/Loading_2.gif"
          className="img-fluid rounded-top"
          alt="Loading..."
        />
      </div>
    );
  }

  const formatMessageContent = (content) => {
    return content
      .replace(
        /A new ticket has been created:/g,
        '<span style="color: gray;">A new ticket has been created:</span>'
      )
      .replace(
        /Description:/g,
        '<span style="color: #818589;">Description:</span>'
      )
      .replace(
        /Created by:/g,
        '<span style="color: #818589;">Created by:</span>'
      )
      .replace(
        /([\w\s]+) \(([^)]+)\) has replied through the portal/g,
        '<span style="color: gray;">$1 ($2) has replied through the portal</span>'
      )
      .replace(/\n/g, '<br />');
  };

  return (
    <div className="ticket-view-container">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-primary">Ticket #SAL0{ticketData.ticketId}</h2>
        <div className="d-flex">
          <button className="btn btn-secondary me-2" onClick={() => navigate(`/tickets`)}> Back to Tickets </button>
          <button className="btn btn-secondary" onClick={() => navigate(`/applications/view/${ticketData.applicationId}`)}> Back to Application </button>
        </div>
      </div>

      <div className="ticket-details mb-4">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th scope="row">Created By</th>
              <td>{ticketData.createdBy}</td>
            </tr>
            <tr>
              <th scope="row">Created On</th>
              <td>{new Date(ticketData.createdOn).toLocaleString()}</td>
            </tr>
            <tr>
              <th scope="row">Status</th>
              <td>
                <span className={`badge bg-${status === 'resolved' ? 'success' : status === 'cancelled' ? 'danger' : 'warning'}`}>
                  {status}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="status-buttons mb-4">{renderStatusButtons()}</div>

      <div className="messages-container">
        <h4 className="text-secondary">Messages</h4>
        <div className="message-list">
          {ticketData.messages.map((message, index) => (
            <div key={index} className={`message-item ${getMessageType(message)}`}>
              <p className="message-meta text-muted">
              <strong>From:</strong> {message.from || "Unknown"} -{' '}
                <span>{message.date ? new Date(message.date).toLocaleString() : 'Unknown Date'}</span>
              </p>
              <p className="message-content" dangerouslySetInnerHTML={{ __html: formatMessageContent(message.content) }}></p>
            </div>
          ))}
        </div>
      </div>

      <div className="reply-section mt-4">
        <textarea
          className="form-control mb-3"
          rows="4"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Write your reply here..."
        ></textarea>
        <button className={`btn btn-primary ${inactive ? 'disabled' : ''}`} onClick={handleReply} disabled={inactive}>
          Send Reply
        </button>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton className='modal-header'>
            <Modal.Title className='modal-title custom-modal-title'>Confirm Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to <strong>{selectedStatus}</strong> this ticket? You can't undo this action.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              No
            </Button>
            <Button variant="success" onClick={handleStatusChange}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer style={{ top: "60px", right: "10px" }} />
      </div>
    </div>
  );
};

export default TicketView;